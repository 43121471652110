import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1280.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">

<path d="M6250 10370 c-50 -12 -118 -39 -100 -40 15 0 90 -69 90 -82 0 -4 -10
-9 -22 -11 l-23 -4 23 -8 c12 -4 22 -16 22 -26 0 -10 5 -21 10 -24 6 -4 8 -10
6 -14 -3 -4 6 -29 20 -56 l24 -49 0 -385 c-1 -259 3 -387 10 -389 5 -2 10 -12
10 -23 0 -10 4 -19 10 -19 13 0 -10 -50 -24 -51 -6 0 -2 -4 9 -9 18 -8 18 -9
2 -9 -10 -1 -17 -7 -15 -13 6 -16 -38 -52 -52 -43 -6 3 -7 0 -4 -9 4 -11 0
-16 -15 -16 -12 0 -21 -4 -21 -10 0 -9 -41 -35 -162 -103 -27 -15 -79 -47
-116 -71 -37 -24 -150 -94 -252 -156 -102 -62 -221 -135 -265 -162 -44 -27
-114 -70 -155 -95 -41 -25 -121 -75 -178 -110 -114 -73 -111 -73 -227 -28 -54
21 -83 26 -125 23 -107 -8 -208 -86 -224 -173 -4 -20 -5 -39 -3 -41 2 -2 23
14 46 36 38 37 46 40 96 40 60 0 154 -17 172 -32 14 -11 28 -4 382 211 46 28
93 51 103 51 10 0 36 -9 58 -20 22 -11 42 -20 46 -20 4 0 -1 9 -11 20 -11 12
-13 20 -6 20 7 0 10 4 6 9 -3 5 4 12 15 16 11 3 20 14 20 22 0 9 3 13 6 10 11
-10 44 12 44 29 0 12 6 15 25 10 18 -5 28 -1 37 14 7 11 18 20 24 20 6 0 17 6
23 14 7 8 26 18 42 21 16 4 29 10 29 13 0 4 29 23 65 41 36 19 65 38 65 43 0
5 5 6 10 3 6 -3 13 1 16 9 3 9 12 16 19 16 6 0 24 11 39 25 15 14 33 25 40 25
8 0 19 7 26 15 7 8 18 15 26 15 7 0 19 6 26 13 27 25 88 68 88 61 0 -4 8 0 18
8 28 25 155 67 193 63 72 -7 136 -27 205 -65 40 -21 75 -36 78 -33 3 4 16 -3
29 -13 24 -22 64 -49 117 -78 19 -11 44 -24 55 -30 35 -20 79 -50 100 -69 11
-10 37 -27 57 -38 20 -10 39 -23 43 -29 3 -5 13 -10 21 -10 8 0 49 -25 89 -55
41 -30 79 -55 85 -55 16 0 47 -39 45 -59 -1 -11 -7 -27 -15 -36 -7 -9 37 9 99
39 l113 55 46 -23 c173 -88 476 -323 587 -456 189 -227 312 -501 366 -820 26
-152 38 -643 20 -800 -20 -180 -91 -278 -282 -392 -122 -72 -235 -129 -243
-121 -3 4 -6 245 -6 537 0 292 -5 581 -10 643 -22 243 -125 452 -319 643 -53
52 -117 106 -142 119 l-46 24 -29 -20 c-16 -11 -105 -68 -199 -125 -93 -57
-298 -185 -455 -284 -157 -100 -307 -193 -333 -209 l-49 -28 -46 22 c-26 13
-85 47 -132 76 -47 29 -152 94 -235 145 -82 51 -213 132 -290 180 -207 128
-247 152 -251 147 -3 -2 -4 -437 -4 -966 0 -528 -4 -961 -8 -961 -14 0 -207
108 -470 264 -34 20 -66 35 -71 32 -5 -3 -11 -23 -15 -43 -37 -228 -359 -405
-583 -321 -62 23 -175 119 -159 135 5 4 11 2 13 -4 3 -9 10 -10 24 -3 10 6 19
8 19 4 0 -3 7 -1 15 6 9 7 18 11 21 8 3 -3 18 6 33 21 48 44 82 71 92 71 6 0
7 5 4 10 -3 6 -1 10 5 10 21 0 144 134 171 185 48 90 54 141 53 505 0 336 -7
455 -25 444 -5 -3 -9 -11 -8 -17 2 -7 -1 -33 -5 -59 -8 -46 -31 -68 -56 -53
-5 3 -3 -15 5 -40 10 -33 10 -45 2 -42 -7 3 -11 10 -10 16 2 6 -5 11 -16 11
-10 0 -24 7 -31 15 -7 8 -20 15 -29 15 -12 0 -13 2 -3 9 11 6 10 10 -3 15 -14
5 -17 16 -13 58 5 48 3 55 -24 80 -17 15 -39 28 -49 28 -15 0 -19 7 -19 40 0
27 -4 40 -14 40 -15 0 -32 126 -21 160 5 14 2 20 -9 20 -9 0 -16 5 -16 10 0 6
-7 10 -16 10 -10 0 -14 7 -12 18 2 9 8 16 14 15 17 -3 4 176 -15 208 -15 24
-16 39 -10 86 5 32 6 187 4 346 -5 277 -4 288 15 307 20 20 20 20 -10 20 -28
0 -30 2 -30 39 0 22 5 41 12 43 10 4 12 44 11 161 -2 86 1 157 6 157 13 0 18
79 6 84 -5 2 -2 5 8 5 20 1 24 50 6 83 -8 14 -8 23 0 31 8 8 11 4 11 -16 0
-15 5 -27 10 -27 6 0 10 18 10 40 0 22 5 40 10 40 6 0 9 3 8 8 -5 21 16 72 33
77 25 8 24 22 -2 30 -26 8 -38 -3 -19 -16 12 -9 11 -10 -4 -5 -40 13 -417
-206 -493 -285 -88 -94 -149 -246 -161 -409 -5 -58 -8 -476 -7 -929 1 -637 4
-826 13 -829 7 -2 18 -10 26 -17 7 -8 17 -11 20 -7 4 3 11 0 16 -8 8 -13 55
-15 304 -14 l295 0 -29 -35 c-31 -40 -172 -192 -326 -351 -237 -245 -309 -323
-316 -338 -11 -30 3 -234 23 -316 10 -43 19 -89 19 -102 0 -12 4 -25 9 -28 5
-3 12 -23 16 -43 3 -21 18 -58 32 -83 27 -48 111 -140 127 -140 5 0 18 -7 28
-17 21 -19 30 -24 130 -77 40 -21 96 -53 123 -71 28 -18 65 -38 83 -46 17 -8
32 -17 32 -21 0 -5 7 -8 15 -8 8 0 15 -3 15 -7 0 -5 28 -21 63 -38 34 -16 64
-32 67 -35 12 -12 129 -80 138 -80 6 0 12 -4 14 -8 2 -5 35 -23 73 -42 39 -18
88 -45 110 -59 87 -55 254 -140 290 -147 58 -12 120 -10 150 5 17 8 37 14 45
13 10 -1 11 1 3 4 -22 10 -14 22 10 17 15 -3 18 -2 9 4 -9 7 3 16 40 33 144
64 308 211 424 381 70 103 100 168 127 279 20 81 21 116 24 823 3 504 7 737
14 737 6 0 63 -32 127 -72 65 -39 162 -98 217 -131 147 -89 175 -112 176 -150
0 -18 4 -41 9 -52 8 -18 9 -18 9 5 1 24 31 60 50 60 5 0 11 6 14 13 5 14 159
107 177 107 5 0 10 4 10 9 0 5 16 15 35 22 19 7 35 17 35 21 0 4 13 8 29 8 16
0 31 4 33 10 4 12 78 -7 78 -20 0 -6 6 -10 14 -10 10 0 17 -16 22 -47 5 -27 9
-51 10 -55 1 -5 2 -79 3 -165 0 -86 3 -154 5 -152 3 2 7 135 11 294 3 160 8
309 11 332 l5 41 157 94 c86 51 202 121 257 155 55 35 106 63 113 63 9 0 12
-139 12 -659 0 -384 -4 -670 -9 -684 -28 -74 -83 -113 -566 -402 -327 -196
-488 -286 -534 -300 -21 -6 -23 -5 -17 11 15 39 48 511 35 496 -3 -4 -12 -72
-18 -152 -18 -215 -37 -322 -82 -450 -70 -202 -181 -382 -327 -532 -115 -119
-176 -166 -320 -248 -59 -34 -119 -70 -132 -80 -24 -19 -24 -19 -2 -13 17 4
21 2 16 -10 -4 -12 -1 -14 15 -10 12 3 21 1 21 -6 0 -6 5 -11 11 -11 6 0 26
-7 45 -15 19 -8 50 -22 70 -31 19 -8 38 -22 40 -29 3 -8 18 -17 34 -20 16 -3
34 -12 40 -20 6 -8 18 -11 26 -8 7 3 17 -2 20 -11 3 -9 14 -16 23 -16 9 0 52
-18 95 -40 43 -22 87 -40 97 -40 11 0 19 -4 19 -8 0 -11 131 -77 144 -73 5 1
12 -2 15 -7 10 -15 139 -30 151 -18 8 8 14 6 21 -4 8 -11 12 -12 16 -2 2 6 18
12 34 12 61 0 247 64 319 110 14 9 37 22 53 30 15 7 27 17 27 21 0 5 7 6 15 3
8 -4 15 -2 15 4 0 9 114 75 138 78 8 2 22 10 31 18 10 9 22 16 28 16 6 0 16 7
24 16 7 8 29 24 48 35 20 11 54 30 76 44 22 13 54 31 72 40 18 9 37 23 44 31
6 8 18 14 25 14 8 0 14 5 14 10 0 6 7 10 15 10 8 0 15 4 15 8 0 4 20 15 44 24
25 9 72 35 106 57 34 23 66 41 71 41 5 0 12 7 15 15 4 8 13 15 21 15 9 0 48
19 87 43 39 23 85 49 101 57 17 8 45 25 63 39 18 13 35 21 38 18 4 -3 16 3 28
14 11 11 39 29 61 40 22 11 47 23 55 28 8 5 45 24 83 41 37 18 67 36 67 41 0
5 6 9 13 9 7 0 42 20 79 45 37 25 72 45 78 45 5 0 10 7 10 16 0 8 4 13 9 9 5
-3 16 0 23 7 7 7 49 31 93 53 90 45 266 160 258 169 -3 3 1 8 10 12 9 3 26 21
38 40 12 20 26 31 32 27 7 -3 9 -3 5 1 -4 5 14 36 40 70 26 33 60 90 76 125
58 130 57 93 54 1693 -3 1390 -4 1466 -22 1518 -61 181 -147 281 -342 394
-311 180 -428 246 -436 246 -5 0 -21 11 -36 25 -15 14 -32 25 -38 25 -6 0 -23
9 -38 21 -14 11 -26 17 -26 13 0 -4 -9 1 -19 10 -11 10 -25 15 -32 10 -8 -5
-10 -3 -4 6 6 10 4 12 -9 7 -10 -4 -15 -3 -11 3 7 11 -42 33 -55 25 -5 -3 -10
1 -13 7 -2 7 -8 10 -12 6 -4 -4 7 -21 24 -38 35 -35 38 -42 16 -34 -9 4 -15 0
-15 -9 0 -8 -7 -17 -15 -21 -9 -3 -15 -19 -15 -41 0 -28 4 -35 19 -35 11 0 23
-12 31 -30 13 -32 25 -38 33 -17 4 10 7 10 18 0 11 -11 10 -13 -4 -13 -12 0
-17 -8 -17 -30 0 -20 -5 -30 -15 -30 -16 0 -19 -17 -9 -44 9 -23 24 -20 24 4
0 11 5 20 10 20 6 0 10 -18 10 -40 0 -26 -5 -40 -13 -40 -9 0 -12 -11 -8 -37
l5 -38 7 35 c5 27 7 18 8 -37 0 -43 -3 -73 -9 -73 -5 0 -10 17 -10 38 0 27 -3
33 -10 22 -5 -8 -10 -28 -11 -45 -1 -28 -2 -27 -9 8 -7 40 -34 76 -47 63 -4
-4 -2 -10 4 -14 8 -5 9 -21 3 -52 -5 -25 -6 -106 -4 -180 3 -82 1 -130 -4
-122 -8 11 -16 9 -46 -10 -19 -12 -36 -29 -36 -36 0 -7 5 -10 10 -7 6 4 10
-10 10 -34 0 -23 -4 -41 -10 -41 -5 0 -10 14 -10 30 0 17 -3 30 -6 30 -9 0
-68 -42 -76 -55 -5 -7 -14 -6 -28 3 -24 15 -50 16 -50 2 0 -5 -18 -10 -40 -10
-31 0 -40 -4 -40 -17 0 -22 -17 -5 -22 22 -2 13 -12 20 -28 21 -14 0 -43 6
-65 12 -25 8 -41 8 -43 2 -2 -6 -10 -6 -22 0 -22 12 -46 4 -54 -17 -5 -13 -9
-13 -23 -2 -9 8 -28 20 -42 27 -14 7 -19 10 -10 8 8 -2 25 2 37 8 21 11 19 12
-17 11 -21 0 -37 -4 -34 -9 3 -4 -5 -5 -18 -1 l-24 8 25 -22 25 -22 -35 17
c-76 36 -549 309 -656 379 -18 11 -47 29 -65 40 -18 11 -49 30 -69 43 -19 12
-52 31 -72 42 -50 27 -55 39 -20 46 19 4 29 12 28 22 -1 11 9 16 33 18 18 1
36 6 40 11 3 5 19 26 35 47 19 24 26 40 19 44 -7 5 -3 16 12 34 13 15 26 24
29 21 3 -4 6 3 6 14 0 31 106 148 364 400 131 128 235 236 232 242 -8 12 -244
153 -464 277 -136 76 -172 87 -302 90 -63 2 -131 -1 -150 -6z m77 -682 c-3 -7
-5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m1660 -204 c-3 -3 -12 -4 -19
-1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-652 -914 c3 -5 -1 -10 -9 -10 -9 0 -16
5 -16 10 0 6 4 10 9 10 6 0 13 -4 16 -10z m385 -19 c0 -5 -7 -12 -16 -15 -22
-9 -24 -8 -24 9 0 8 9 15 20 15 11 0 20 -4 20 -9z m-293 -7 c-3 -3 -12 -4 -19
-1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-2977 -1524 c0 -5 -5 -10 -11 -10 -5 0
-7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z"/>
<path d="M7472 9804 c10 -10 20 -15 24 -12 3 4 -5 12 -18 18 -22 12 -22 12 -6
-6z"/>
<path d="M7780 9616 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M4290 9283 c-14 -7 -19 -13 -12 -13 7 0 11 -4 8 -9 -3 -5 -15 -7 -25
-4 -10 3 -21 0 -25 -5 -10 -16 44 -21 60 -5 8 8 12 18 9 23 -3 5 1 10 7 13 7
2 11 6 8 9 -3 3 -16 -1 -30 -9z"/>
<path d="M6270 9139 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M7880 8850 c-13 -9 -13 -11 0 -20 24 -16 40 -12 40 10 0 22 -16 26
-40 10z"/>
<path d="M7892 8748 c-18 -18 -15 -28 8 -28 13 0 20 7 20 20 0 23 -10 26 -28
8z"/>
<path d="M7752 8620 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M7340 8600 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4801 8184 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4804 7910 c0 -129 2 -182 3 -117 2 64 2 170 0 235 -1 64 -3 11 -3
-118z"/>
<path d="M4801 7534 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4481 7434 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4200 7400 c0 -22 5 -40 10 -40 6 0 10 18 10 40 0 22 -4 40 -10 40
-5 0 -10 -18 -10 -40z"/>
<path d="M4801 7294 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4470 7220 c0 -5 7 -7 15 -4 8 4 15 8 15 10 0 2 -7 4 -15 4 -8 0 -15
-4 -15 -10z"/>
<path d="M4803 7100 c0 -36 2 -50 4 -32 2 17 2 47 0 65 -2 17 -4 3 -4 -33z"/>
<path d="M4803 6840 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M3804 3958 c-4 -7 -10 -279 -12 -605 -4 -561 -3 -593 14 -593 9 0 15
-3 11 -6 -9 -9 25 -40 49 -46 33 -8 127 19 146 42 9 11 21 20 27 20 6 0 11 15
11 33 1 151 14 459 21 489 5 20 19 47 30 60 11 12 18 17 14 11 -6 -13 9 -17
52 -13 12 0 39 -4 61 -9 31 -9 45 -21 70 -62 18 -28 32 -58 32 -67 0 -12 3
-13 8 -5 4 7 6 -84 4 -201 -3 -167 -1 -221 9 -245 8 -17 18 -31 22 -31 5 0 5
-4 2 -10 -7 -11 -5 -11 70 -15 56 -3 125 25 125 50 0 8 7 15 15 15 23 0 22
615 -1 682 -21 64 -76 123 -148 161 -56 29 -69 32 -156 32 -87 0 -102 -3 -158
-31 -60 -29 -62 -30 -63 -10 0 12 0 86 1 166 1 80 -2 158 -5 173 l-7 27 -118
0 c-82 0 -121 -4 -126 -12z"/>
<path d="M1486 3942 c-9 -15 -24 -253 -41 -647 -8 -198 -17 -397 -19 -442 -4
-66 -2 -82 10 -87 8 -3 12 -10 9 -16 -3 -5 -1 -10 4 -10 6 0 11 -6 11 -14 0
-8 4 -17 9 -20 17 -11 131 -18 131 -8 0 5 13 15 29 22 71 29 83 77 96 380 3
74 8 173 12 220 l6 85 14 -50 c8 -27 21 -65 29 -83 8 -18 12 -37 9 -42 -3 -6
-1 -10 5 -10 6 0 10 -6 8 -12 -1 -7 4 -23 11 -36 7 -13 10 -30 7 -38 -3 -8 -2
-13 2 -10 5 3 19 -27 32 -67 12 -40 25 -78 28 -86 4 -10 1 -12 -8 -6 -9 5 -11
4 -6 -4 4 -6 12 -8 18 -5 8 5 9 -4 5 -27 -5 -22 -4 -29 2 -20 11 17 20 -24 24
-101 1 -35 6 -48 20 -52 12 -3 15 -10 10 -22 -5 -13 -3 -16 8 -12 9 3 20 -2
27 -12 13 -21 65 -30 109 -18 32 9 81 51 109 93 18 27 166 458 205 594 16 57
30 106 32 108 4 3 29 -261 47 -487 6 -74 16 -162 21 -195 12 -64 35 -119 48
-111 4 3 25 1 45 -5 52 -14 92 1 145 56 l43 45 -6 67 c-4 38 -16 140 -26 228
-11 88 -33 286 -50 440 -17 154 -35 310 -42 348 l-11 68 -146 -3 -145 -3 -71
-210 c-40 -115 -88 -253 -107 -305 -19 -52 -48 -143 -64 -202 -16 -60 -31
-108 -34 -108 -3 0 -14 30 -24 68 -11 37 -50 150 -88 252 -39 102 -89 239
-113 306 -24 67 -53 140 -64 163 l-21 41 -123 0 c-67 0 -132 3 -143 6 -12 3
-24 1 -28 -4z"/>
<path d="M7585 3941 c-3 -7 -8 -267 -10 -579 -4 -635 -3 -641 59 -661 47 -16
194 -27 256 -19 30 3 84 6 120 7 44 1 79 8 105 21 42 22 59 25 51 11 -4 -5 3
-7 14 -4 11 3 20 9 20 14 0 5 10 9 23 9 26 0 100 57 92 71 -6 8 11 13 42 12 7
-1 10 3 8 8 -3 4 9 16 27 25 86 45 188 172 236 294 23 58 26 79 26 195 -1 115
-4 139 -28 210 -61 180 -176 293 -368 362 -71 26 -80 27 -369 31 -212 3 -299
1 -304 -7z m429 -345 c70 -3 94 -8 117 -25 15 -12 32 -21 36 -21 12 0 109
-112 134 -155 19 -31 21 -46 16 -100 -10 -110 -56 -173 -159 -220 -51 -23 -66
-25 -160 -23 l-103 3 1 250 c0 265 3 305 19 299 6 -2 50 -5 99 -8z"/>
<path d="M9960 3812 c-67 -44 -121 -88 -257 -209 -41 -37 -42 -39 -42 -105 -1
-37 4 -70 9 -73 6 -3 10 -14 10 -24 0 -21 22 -71 33 -71 4 0 7 -12 8 -27 0
-18 3 -22 7 -13 3 8 12 -67 18 -168 7 -101 17 -189 23 -196 6 -7 8 -22 4 -31
-4 -13 -3 -16 5 -11 8 5 10 2 6 -8 -3 -8 1 -25 10 -38 9 -12 16 -32 16 -44 0
-12 7 -27 15 -34 8 -7 15 -18 15 -24 0 -11 53 -39 99 -52 61 -17 144 0 186 37
11 10 40 28 65 41 l45 23 3 128 3 127 -76 0 c-118 0 -115 -5 -115 166 0 137 1
145 19 139 23 -7 59 7 88 34 12 12 29 21 38 21 8 0 15 6 15 14 0 7 9 16 20 19
18 5 20 14 20 111 l0 105 -97 3 -98 3 -7 94 c-4 52 -9 98 -10 103 -2 5 -37
-13 -78 -40z"/>
<path d="M5923 3745 c-75 -52 -217 -141 -236 -148 -6 -2 -12 -37 -15 -79 -4
-70 -3 -76 19 -92 13 -9 18 -16 11 -16 -6 0 -12 -5 -12 -11 0 -6 7 -8 16 -5
12 5 15 2 10 -10 -4 -11 -2 -15 8 -11 21 8 26 -23 26 -193 0 -85 5 -184 11
-220 6 -36 11 -70 11 -77 1 -7 6 -9 11 -6 9 6 9 0 -5 -47 -3 -8 -3 -12 -1 -7
7 12 24 8 22 -5 -4 -25 23 -78 39 -78 8 0 12 -3 9 -7 -12 -11 64 -41 121 -49
30 -3 71 -2 96 5 52 13 135 61 136 77 0 7 7 14 15 18 12 4 15 29 15 131 0 69
-4 125 -9 125 -5 0 -26 -7 -47 -16 -49 -21 -112 -13 -136 16 -20 25 -23 121
-11 303 l6 97 84 0 84 0 -3 96 -3 96 -80 2 -80 1 -3 30 c-7 94 -14 135 -22
135 -5 0 -44 -25 -87 -55z"/>
<path d="M9145 3673 c-16 -1 -73 -8 -126 -14 -53 -6 -101 -14 -108 -18 -17
-11 -22 -49 -15 -119 l6 -63 72 5 c47 3 61 2 41 -3 -16 -5 -22 -9 -12 -10 9 0
17 -8 17 -16 0 -8 3 -14 8 -13 4 0 29 -5 55 -13 l48 -13 -57 -13 c-131 -30
-255 -116 -304 -213 -28 -55 -36 -134 -20 -201 8 -30 14 -61 15 -69 12 -98 19
-115 64 -158 30 -28 58 -46 77 -47 131 -13 141 -13 201 7 35 11 60 24 58 29
-3 4 11 13 30 20 19 7 35 16 35 19 0 3 15 21 33 39 l32 34 7 -34 c20 -102 22
-104 57 -106 33 -1 115 32 156 63 19 14 20 27 20 362 0 338 -1 348 -22 388
-26 50 -83 101 -138 125 -38 17 -154 40 -185 37 -8 0 -28 -3 -45 -5z m-59
-541 c-3 -5 9 -9 26 -10 17 0 47 -9 65 -19 33 -17 34 -19 28 -63 -8 -53 -16
-60 -72 -60 -56 0 -87 37 -80 93 3 21 11 44 17 52 13 16 25 20 16 7z"/>
<path d="M10559 3655 c-53 -6 -101 -15 -107 -19 -13 -8 -16 -103 -6 -155 6
-27 11 -31 40 -31 18 0 35 -4 38 -9 3 -4 31 -11 62 -14 31 -3 62 -11 67 -16 6
-6 19 -11 29 -11 10 0 21 -7 25 -16 3 -9 15 -20 27 -24 16 -7 9 -9 -29 -9
-104 -2 -241 -59 -307 -128 -53 -55 -73 -125 -73 -251 0 -75 4 -105 13 -111 8
-5 12 -17 10 -28 -4 -25 51 -113 71 -113 8 0 23 -8 33 -17 15 -13 39 -17 116
-17 85 0 103 3 148 26 27 15 56 34 64 43 7 9 25 22 40 30 23 12 27 11 37 -4 6
-9 14 -31 18 -49 7 -32 7 -32 69 -32 50 0 67 4 81 20 10 11 23 20 29 20 6 0
19 12 29 26 17 23 18 48 15 357 -3 321 -4 334 -26 382 -27 59 -45 80 -95 111
-82 53 -215 65 -418 39z m111 -560 c7 -8 19 -15 27 -15 8 0 11 -5 8 -10 -4 -6
7 -16 24 -23 17 -7 29 -16 25 -19 -3 -4 3 -4 15 -1 31 8 28 -30 -5 -56 -76
-60 -180 34 -134 120 13 23 23 24 40 4z"/>
<path d="M3180 3639 c-36 -5 -96 -19 -135 -31 l-70 -23 2 -79 c1 -43 4 -81 7
-84 3 -3 44 0 91 7 90 14 199 7 174 -11 -10 -7 -5 -8 14 -3 18 3 26 2 23 -4
-4 -5 4 -11 16 -13 15 -2 23 -10 23 -23 0 -11 6 -26 13 -34 20 -21 6 -25 -43
-11 -72 20 -149 8 -247 -39 -141 -67 -188 -141 -188 -296 0 -56 5 -87 15 -103
9 -12 15 -30 13 -39 -2 -10 2 -17 9 -16 9 2 10 -4 4 -20 -17 -45 45 -107 122
-121 20 -4 37 -11 37 -16 0 -16 72 -11 127 9 69 25 116 59 109 78 -4 11 -2 13
9 9 9 -3 15 0 15 7 0 7 14 26 30 42 l30 29 0 -30 c0 -53 25 -106 56 -119 49
-20 102 -7 145 36 l39 39 -1 203 c0 111 -4 261 -8 333 -7 116 -11 137 -34 177
-68 116 -216 171 -397 146z m32 -506 c-15 -6 63 -63 86 -63 6 0 12 -6 12 -14
0 -8 4 -18 10 -21 6 -4 7 1 4 12 -4 14 -4 15 4 4 26 -36 -36 -101 -97 -101
-54 0 -110 43 -111 85 0 39 58 103 95 103 6 0 4 -2 -3 -5z"/>
<path d="M6620 3641 c-82 -12 -163 -30 -180 -41 -18 -11 -28 -144 -11 -164 8
-10 24 -11 68 -4 32 5 78 7 103 4 31 -4 39 -3 25 3 -18 9 -17 10 8 10 16 1 26
-3 22 -9 -3 -5 6 -17 20 -26 18 -11 25 -24 22 -36 -4 -13 2 -20 21 -24 50 -11
47 -14 -9 -8 -66 6 -132 -8 -214 -48 -124 -59 -179 -147 -179 -284 0 -61 5
-90 21 -122 11 -23 16 -39 12 -36 -5 3 -9 1 -9 -5 0 -5 5 -13 10 -16 6 -3 10
-11 10 -17 0 -7 -6 -6 -15 2 -8 7 -15 8 -15 2 0 -5 7 -15 15 -22 8 -7 15 -19
15 -26 0 -8 9 -19 20 -26 11 -7 20 -17 20 -23 0 -6 33 -20 74 -32 66 -19 80
-20 133 -9 57 12 94 28 121 54 7 6 18 12 23 12 5 0 9 6 9 13 0 6 18 26 39 44
l39 32 6 -27 c4 -15 12 -47 19 -71 l12 -43 56 4 c45 3 63 10 98 39 l42 34 -4
310 c-3 330 -7 355 -57 428 -30 43 -97 93 -150 112 -54 19 -166 26 -240 16z
m88 -498 c-24 -4 -26 -6 -17 -35 11 -38 24 -48 61 -43 22 3 28 0 28 -16 0 -49
-45 -99 -89 -99 -33 0 -88 31 -102 56 -32 61 33 146 110 143 29 -2 31 -3 9 -6z"/>
<path d="M4793 3608 c-4 -7 -9 -141 -11 -299 -3 -239 -1 -295 13 -348 9 -35
23 -74 32 -87 10 -16 11 -24 4 -24 -7 0 -10 -4 -6 -10 3 -5 13 -6 23 -3 16 6
16 6 0 -11 -19 -19 -16 -25 31 -71 44 -43 59 -53 96 -65 59 -19 226 4 210 30
-3 5 -1 10 4 10 6 0 34 24 63 52 l53 53 3 -30 c2 -17 9 -34 15 -40 7 -5 12
-16 13 -22 3 -43 3 -43 60 -39 47 3 61 9 83 33 14 16 33 32 42 35 20 8 29 190
29 580 l0 268 -124 0 -123 0 -7 -62 c-3 -35 -9 -162 -12 -283 l-7 -220 -33
-32 c-40 -41 -94 -45 -145 -10 -58 39 -60 49 -58 302 1 127 1 247 0 268 l-1
37 -119 0 c-85 0 -122 -4 -128 -12z"/>
<path d="M3270 3386 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
